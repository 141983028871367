@tailwind base;
@tailwind components;
@tailwind utilities;

@import './utilities';

@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';

body {
    color: rgb(var(--foreground-rgb));
    background: linear-gradient(to bottom, transparent, rgb(var(--background-end-rgb))) rgb(var(--background-start-rgb));
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
}

.dfire-container {
    width: 100%;
    max-width: 1092px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;

    @media screen and (max-width: 600px) {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.loader {
    position: relative;
    margin: auto;
    box-sizing: border-box;

    width: 5vw;
    height: 5vw;
    min-width: 50px;
    min-height: 50px;

    border-radius: 50%;
    border: 4px solid rgba(255, 255, 255, 0.1);
    transform-origin: 50% 50%;
    transform: perspective(200px) rotateX(66deg) scale(1.5);

    animation: spinner-wiggle 1.2s infinite;
}
.loader:before,
.loader:after {
    content: '';
    position: absolute;
    inset: -4px;
    border-radius: 50%;
    box-sizing: border-box;
    border: 4px solid #0000;
    animation: spinner-spin 1.2s cubic-bezier(0.6, 0.2, 0, 0.8) infinite, spinner-fade 1.2s linear infinite;
}
.loader:before {
    border-top-color: #7ac8a7;
}
.loader:after {
    border-top-color: #a91a1e;
    animation-delay: 0.4s;
}

@keyframes spinner-spin {
    100% {
        transform: rotate(360deg);
    }
}
@keyframes spinner-fade {
    25%,
    75% {
        opacity: 0.1;
    }
    50% {
        opacity: 1;
    }
}
