.image-wrap {
    width: 100%;
    position: relative;

    > div {
        position: unset !important;
    }

    .image {
        object-fit: contain;
        width: 100% !important;
        position: relative !important;
        height: unset !important;
    }

    .image-contain {
        object-fit: contain;
        width: 100% !important;
        position: relative !important;
        height: unset !important;
    }

    .image-cover {
        object-fit: cover;
        width: 100% !important;
        position: relative !important;
    }
}
